import React from 'react'

function About() {
    return (
        <section id="about" style={{}}>
            <div className="banner-main-container banner-section">
                <div className="banner-center-container">
                    <h2>Acerca de mí</h2>
                    <div className="about-image"></div>
                    <h4>Daniela Casares</h4>
                    <p className="about-text generic-text">Licenciada en Dietética y Nutrición y fiel creeyente que la nutrición no solo consiste en llevar una dieta balanceada y tener una rutina de ejercicio. <br/><br/>Para mí, la clave es disfrutar lo que comes sintiéndote saludable y cuidando que es lo que mejor se adapta a ti. <br/><br/>¡Permíteme ayudarte a llegar a tus objetivos!</p>                    
                </div>
            </div>
        </section>
    )
}

export default About

import React from 'react'
import Card from 'react-bootstrap/Card'
import {services} from '../config/app'

function Services() {
    const getServiceItems = (items) => {
        return(
            <div className="check-list generic-text">
                {items.map((item, index) => {
                    return (
                        <span key={index}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check-circle-fill icon-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                            {item}
                        </span>
                    )
                })}
            </div>
        )
    }

    const getServices = () => {
        return(
            <div className="services-container">
                {services.map((service, index) => {
                    return(
                        <Card key={index} style={{ width: '18rem', position: 'static'}}>
                            <Card.Img variant="top" src={service.img} style={{height: '180px'}}/>
                            <Card.Body>
                                <Card.Title className="d-flex flex-column justify-content-center">
                                    <div>{service.name}</div>
                                    <div>{service.price}</div>
                                </Card.Title>
                                {getServiceItems(service.items)}
                            </Card.Body>
                        </Card>
                    )
                })}
            </div>
        )
    }

    return (
        <section id="services" style={{backgroundColor: 'whitesmoke'}}>
            <div className="banner-main-container banner-section">
                <div className="banner-center-container">
                    <h2>Servicios</h2>
                    <p>Conoce nuestros servicios mediante los cuales podrás reducir tu porcentaje de grasa, incrementar tu porcentaje de músculo y mejorar tu rendimiento deportivo.</p>
                    {getServices()}
                    <a href="https://api.whatsapp.com/send?text=¡Hola%20Navena!&phone=525527542800" target="_blank" rel="noreferrer" className="btn-main btn-navena-secondary" style={{margin: '24px'}}>Agenda tu cita</a>
                </div>
            </div>
        </section>
    )
}

export default Services

import React from 'react'

function Footer() {
    return (
        <footer id="footer" className="footer">
            <div className="footer-content">
            <div className="flex-display flex-column-direction footer-section">
                <div className="footer-img"></div>
                <div className="flex-display flex-row-direction">
                <i className="fa fa-clock-o footer-icon" aria-hidden="true"></i>
                <p className="footer-text">Lun - Jue: 9am - 6pm</p>
                </div>
                <div className="flex-display flex-row-direction">
                <i className="fa fa-clock-o footer-icon" aria-hidden="true"></i>
                <p className="footer-text">Vie - Sáb: 9am - 1pm</p>
                </div>
            </div>
            <div className="flex-display flex-column-direction footer-section">
                <h5>Contacto</h5>
                <div className="flex-display flex-row-direction">
                <i className="fa fa-phone footer-icon" aria-hidden="true"></i>
                <a href="tel:+525527542800" target="_blank" rel="noreferrer"><p className="footer-text">55 2754 2800</p></a>
                </div>
                <div className="flex-display flex-row-direction">
                <i className="fa fa-envelope footer-icon" aria-hidden="true"></i>
                <a href="mailto:contacto@navenanutricion.com" target="_blank" rel="noreferrer"><p className="footer-text">contacto@navenanutricion.com</p></a>
                </div>
                <div className="flex-display flex-row-direction">
                <i className="fa fa-location-arrow footer-icon" aria-hidden="true"></i>
                <p className="footer-text">Ameyalco 10, Del Valle Centro, Benito Juárez, 03100, CDMX</p>
                </div>
            </div>
            <div className="flex-display flex-column-direction footer-section">
                <h5>Síguenos</h5>
                <div className="flex-display flex-row-direction">
                <a href="https://www.facebook.com/Navena-Nutrici%C3%B3n-512438392599625" target="_blank" rel="noreferrer"><i className="fa fa-facebook footer-icon-media" aria-hidden="true"></i> </a>
                <a href="https://www.instagram.com/navena.nutricion" target="_blank" rel="noreferrer"><i className="fa fa-instagram footer-icon-media" aria-hidden="true"></i> </a>
                <a href="https://api.whatsapp.com/send?text=¡Hola%20Navena!&phone=525527542800" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp footer-icon-media" aria-hidden="true"></i> </a>
                </div>
            </div>
            </div>
            <div className="footer-copyright">
            <p style={{color: 'whitesmoke'}}>Copyright &copy; {new Date().getFullYear()} | All rights reserved</p>
            </div>
        </footer>
    )
}

export default Footer

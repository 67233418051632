import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { mainMenu } from '../config/app'

function NavBar() {
    const getLinks = () => {
        return (
            <Nav className="me-auto navbar-items">
                {mainMenu.map((link, index) => {
                    return (
                        <Nav.Link key={index} href={link.href} className="nav-item nav-link nav-link-text">{link.innerText}</Nav.Link>
                    )
                })}
            </Nav>
        )
    }

    return (
        <div id="header-container" className="header-container">
            <Navbar fixed="top" expand="lg" className="navbar-main">
                <Navbar.Brand href="/" style={{padding: '0px'}}>
                    <div id="logo" className="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="navbar-menu">
                    {getLinks()}
                </Navbar.Collapse>
            </Navbar>            
        </div>
    )
}

export default NavBar

import React from 'react'
import Home from './components/Home'
import Services from './components/Services'
import About from './components/About'
import Footer from './components/Footer'

function App() {
  return (
    <div>
      <Home />
      <Services />
      <About />
      <Footer />
    </div>
  )
}

export default App

export const mainMenu = [
    {
        href: '#home',
        innerText: 'Inicio'
    },
    {
        href: '#services',
        innerText: 'Servicios'
    },
    {
        href: '#about',
        innerText: 'Acerca de mí'
    },
]

export const services = [
    {
        name: 'Presencial',
        img: `${process.env.PUBLIC_URL}/images/onsite.jpg`,
        price: '$500',
        items: [
            'Historia clínica',
            'Diagnóstico nutricional',
            '2 menús personalizados',
            'Lista de alimentos',
            'Asesorías en WhatsApp',
            'Diagnóstico de composición corporal'  
        ],
    },
    {
        name: 'Online',
        img: `${process.env.PUBLIC_URL}/images/online.jpg`,
        price: '$450',
        items: [
            'Historia clínica',
            'Diagnóstico nutricional',
            '2 menús personalizados',
            'Lista de alimentos',
            'Asesorías en WhatsApp',
        ],
    },
]
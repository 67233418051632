
import React from 'react'

function Main() {
    return (
        <main id="main" className="banner-area banner-area-white main-banner">
        <div className="banner-main-container">
            <div className="banner-left-container">
                <h1>¡Bienvenido a Navena!</h1>
                <h3>Estás a un paso de mejorar tus hábitos alimenticios. Logra tu peso ideal para cuidar de tu salud toda tu vida.</h3>
                <div className="d-flex flex-row justify-content-center">
                    <a href="#services" className="btn-main btn-navena-primary">Conoce más</a>
                    <a href="https://api.whatsapp.com/send?text=¡Hola%20Navena!&phone=525527542800" target="_blank" rel="noreferrer" className="btn-main btn-navena-secondary">Agenda tu cita</a>
                </div>
            </div>
            <div className="banner-right-container">
                <div className="main-image"></div>
            </div>
        </div>
    </main>
    )
}

export default Main

import React from 'react'
import NavBar from './NavBar'
import Main from './Main'

function Home() {
    return (
        <section id="home">
            <header id="header" className="header">
                <NavBar />
            </header>
            <Main />
        </section>
    )
}

export default Home
